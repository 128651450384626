import {
  Grid,
  Stack,
  TextField,
  Button,
  Typography,
  Paper,
} from "@mui/material";
import { Scene } from "../components/scene/scene";
import { DiceList } from "../components/scene/ui/diceList";
import { PlayerList } from "../components/scene/ui/playerList";
import { useStore } from "../components/store";
import { useContext, useEffect, useState, FormEvent } from "react";
import { ConnectionContext } from "../connection";
import { DiceInfo } from "../components/scene/dice";
import { useParams, useNavigate } from "react-router";
import { devLog } from "../utils";
import { toast } from "react-toastify";
import { UsernameResponse } from "../connection";
import { BackgroundImage } from "../components/frontpage/backgroundImage";
import { useImageBackgroundStore } from "../components/store";
import { responsiveCentered } from "../responsive";

export const Session = () => {
  const { addPlayer, addDiceToPlayer, removePlayer, deleteDiceOfPlayer } =
    useStore();
  const connection = useContext(ConnectionContext);
  const { sessionId } = useParams();
  const [username, setUsername] = useState<string>("");
  const [usernameIsSet, setUsernameIsSet] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { imageIndex } = useImageBackgroundStore();

  const onNewPlayer = (data: { playerId: string; playerName: string }) => {
    devLog(`New player ${data.playerId} with name ${data.playerName} joined`);
    if (data.playerId !== connection.getConnectionId()) {
      toast.info("New player joined");
    }
    addPlayer(data.playerId, data.playerName);
  };

  const onNewDice = (data: { playerId: string; diceInfo: DiceInfo }) => {
    devLog(`Adding dice ${data.diceInfo.id} to player ${data.playerId}`);
    addDiceToPlayer(data.playerId, data.diceInfo);
  };

  const onDeleteDice = (data: { playerId: string; diceId: number }) => {
    devLog(`Deleting dice ${data.diceId} of player ${data.playerId}`);
    if (data.playerId === connection.getConnectionId()) {
      toast.success("Deleted dice");
    }
    deleteDiceOfPlayer(data.playerId, data.diceId);
  };

  const onPlayerLeave = (data: { playerId: string }) => {
    devLog(`Player ${data.playerId} disconnected`);
    removePlayer(data.playerId);
  };

  const addListeners = () => {
    connection.on("newPlayer", (data) => onNewPlayer(data));
    connection.on("newDice", (data) => onNewDice(data));
    connection.on("removePlayer", (data) => onPlayerLeave(data));
    connection.on("deleteDice", (data) => onDeleteDice(data));

    // tell the server this client is ready to handle events
    connection.setReady();
    devLog("client ready");
  };

  const handleSendUsername = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // prevent the page from reloading
    try {
      await connection.setUsername(username);
      devLog(`set username ${username}`);
      addListeners();
      setUsernameIsSet(() => true);
    } catch (error) {
      switch (error) {
        case UsernameResponse.ALREADY_TAKEN:
          setErrorMsg(() => "Username already taken");
          break;
        case UsernameResponse.EMPTY:
          setErrorMsg(() => "Empty username");
          break;
        default:
          setErrorMsg(() => "Unknown error");
          break;
      }
    }
  };

  useEffect(() => {
    if (!connection.connected()) {
      if (!sessionId) {
        devLog("Session ID is undefined");
        return;
      }

      connection
        .connect()
        .then(() => {
          connection.joinSession(sessionId).then(() => {
            devLog("joined session");
          });
        })
        .catch((error) => devLog("error while joining session"));
    }

    // close the connection when the component unmounts
    return () => {
      // remove the own user from the store
      onPlayerLeave({ playerId: connection.getConnectionId() });
      connection.close();
      return;
    };
  }, []);

  if (!sessionId) {
    return null;
  }

  if (!usernameIsSet) {
    return (
      <div>
        <BackgroundImage imageIndex={imageIndex} zIndex={2} />
        <div className="content">
          <Paper
            sx={{
              width: responsiveCentered.width,
              height: "28vh",
              filter: "opacity(0.85)",
              zIndex: 3,
              left: responsiveCentered.left,
              top: "35vh",
              position: "absolute",
            }}
          >
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
              margin="0px"
              width="100%"
              height="100%"
            >
              <Grid item xs={3} padding="16px">
                <form onSubmit={(e) => handleSendUsername(e)}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ height: 55 }}
                    justifyContent="center"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Username"
                      variant="outlined"
                      onChange={(event) =>
                        setUsername(() => event.target.value)
                      }
                      autoFocus
                    />
                    <Button type="submit" variant="contained">
                      <Typography
                        sx={{ textTransform: "none", fontWeight: "bold" }}
                      >
                        Continue
                      </Typography>
                    </Button>
                  </Stack>
                </form>
              </Grid>
              {errorMsg ? (
                <Grid item xs={3} padding="16px">
                  <Typography
                    sx={{ textTransform: "none", color: "error.main" }}
                  >
                    {errorMsg}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Scene />
      <DiceList />
      <PlayerList />
    </div>
  );
};

import { useState, useEffect, useRef } from "react";
import { getRandomInt } from "../../utils";
import { BackgroundImage } from "./backgroundImage";
import { useInterval } from "../useInterval";
import { useImageBackgroundStore } from "../store";

const IMAGE_DURATION_SECONDS = 10;
const NUM_IMAGES = 20;

export const Background = () => {
  const [imageIndex, setImageIndex] = useState<number>(-1);
  const nextImageIndex = useRef<number>(-1);
  const { setBackgroundImageIndex } = useImageBackgroundStore();

  useEffect(() => {
    // set the first background image
    const imageIndexTmp = getRandomInt(0, NUM_IMAGES);

    // set the next background image
    let nextImageIndexTmp = imageIndexTmp;
    while (nextImageIndexTmp === imageIndexTmp) {
      // draw a new random index until a different one than before is drawn
      nextImageIndexTmp = getRandomInt(0, NUM_IMAGES);
    }
    nextImageIndex.current = nextImageIndexTmp;

    setImageIndex(imageIndexTmp);
    setBackgroundImageIndex(nextImageIndexTmp);
  }, []);

  useInterval(() => {
    if (imageIndex === -1 || nextImageIndex.current === -1) {
      // initial image has not been drawn yet -> skip
      return;
    }

    // set the current image to the next one,
    // cache the value for now because the state needs to be updated later
    // as the re-render should occur after the next image is also updated,
    // which is done without triggering a re-render
    const newImageIndex = nextImageIndex.current;

    // generate a new random next image
    let newNextImageIndex = nextImageIndex.current;
    while (newNextImageIndex === nextImageIndex.current) {
      // draw a new random index until a different one than before is drawn
      newNextImageIndex = getRandomInt(0, NUM_IMAGES);
    }
    nextImageIndex.current = newNextImageIndex;

    setImageIndex(newImageIndex);
    setBackgroundImageIndex(newNextImageIndex);
  }, IMAGE_DURATION_SECONDS * 1000);

  if (imageIndex === -1 || nextImageIndex.current === -1) {
    return <div />;
  }

  return (
    <div>
      <BackgroundImage imageIndex={nextImageIndex.current} zIndex={2} />
      <BackgroundImage
        imageIndex={imageIndex}
        zIndex={3}
        dimAnimation={{
          durationSeconds: IMAGE_DURATION_SECONDS,
          delay: 0,
        }}
      />
    </div>
  );
};

export const responsiveCentered = {
  width: {
    xs: "80vw",
    sm: "80vw",
    md: "60vw",
    lg: "60vw",
    xl: "40vw",
  },
  left: {
    xs: "10vw",
    sm: "10vw",
    md: "20vw",
    lg: "20vw",
    xl: "30vw",
  },
};

export const responsiveUIList = {
  iconSize: { xs: 18, sm: 18, md: 20, lg: 22, xl: 22 },
  fontSize: { xs: 12, sm: 12, md: 15, lg: 18, xl: 18 },
  diceListTop: {
    md: 100,
    lg: 100,
    xl: 100,
  },
  diceListLeft: {
    md: 35,
    lg: 35,
    xl: 35,
  },
  diceListBottom: {
    xs: 15,
    sm: 15,
    md: "auto",
    lg: "auto",
    xl: "auto",
  },
  diceListRight: {
    xs: 15,
    sm: 15,
    md: "auto",
    lg: "auto",
    xl: "auto",
  },
  iconMinWidth: { xs: 20, sm: 20, md: 40, lg: 40, xl: 40 },
  playerListBottom: {
    xs: 15,
    sm: 15,
    md: 35,
    lg: 35,
    xl: 35,
  },
  playerListLeft: {
    xs: 15,
    sm: 15,
    md: 35,
    lg: 35,
    xl: 35,
  },
};

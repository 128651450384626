export const isDev = process.env.NODE_ENV === "development";
export const isProd = process.env.NODE_ENV === "production";

// use proxy for local development
export const apiUrl = isDev
  ? "http://localhost:3000/api/v1/"
  : "https://dice2gether.com/api/v1/";

export const assetUrl = isDev
  ? "http://localhost:3000/assets/"
  : "https://dice2gether.com/assets/";

export const socketUrl = isDev
  ? "http://localhost:3000"
  : "https://dice2gether.com";

// the URL for the socket connection needs to be set in the path
export const socketPath = "/api/v1/socket-io";

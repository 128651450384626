import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  responsiveFontSizes,
} from "@mui/material";
import { AccountCircle, Person } from "@mui/icons-material";
import { useStore } from "../../store";
import { responsiveUIList } from "../../../responsive";

export const PlayerList = () => {
  const { playerNames } = useStore();

  return (
    <List
      sx={{
        position: "absolute",
        bottom: responsiveUIList.playerListBottom,
        left: responsiveUIList.playerListLeft,
        backgroundColor: "#262626",
        opacity: 0.75,
        borderRadius: 2,
      }}
      dense
      disablePadding
    >
      <ListItem disablePadding dense sx={{ position: "relative" }}>
        <ListItemButton>
          <ListItemIcon sx={{ minWidth: responsiveUIList.iconMinWidth }}>
            <AccountCircle sx={{ fontSize: responsiveUIList.iconSize }} />
          </ListItemIcon>
          <ListItemText
            primary="Users"
            primaryTypographyProps={{ fontSize: responsiveUIList.fontSize }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
      {playerNames
        .slice(0)
        .reverse()
        .map((playerName) => (
          <ListItem
            sx={{ position: "relative" }}
            disablePadding
            dense
            key={playerName}
          >
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: responsiveUIList.iconMinWidth }}>
                <Person sx={{ fontSize: responsiveUIList.iconSize }} />
              </ListItemIcon>
              <ListItemText
                primary={playerName}
                primaryTypographyProps={{
                  fontSize: responsiveUIList.fontSize,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
};

// taken from https://github.com/byWulf/threejs-dice/blob/master/lib/dice.js
import * as THREE from "three";

const getChamferGeometry = (
  vectors: THREE.Vector3[],
  faces: number[][],
  chamfer: number
): { vectors: THREE.Vector3[]; faces: number[][] } => {
  let chamfer_vectors = [];
  let chamfer_faces = [];
  let corner_faces = new Array(vectors.length);
  for (let i = 0; i < vectors.length; ++i) corner_faces[i] = [];
  for (let i = 0; i < faces.length; ++i) {
    let ii = faces[i],
      fl = ii.length - 1;
    let center_point = new THREE.Vector3();
    let face = new Array(fl);
    for (let j = 0; j < fl; ++j) {
      let vv = vectors[ii[j]].clone();
      center_point.add(vv);
      corner_faces[ii[j]].push((face[j] = chamfer_vectors.push(vv) - 1));
    }
    center_point.divideScalar(fl);
    for (let j = 0; j < fl; ++j) {
      let vv = chamfer_vectors[face[j]];
      vv.subVectors(vv, center_point)
        .multiplyScalar(chamfer)
        .addVectors(vv, center_point);
    }
    face.push(ii[fl]);
    chamfer_faces.push(face);
  }
  for (let i = 0; i < faces.length - 1; ++i) {
    for (let j = i + 1; j < faces.length; ++j) {
      let pairs = [],
        lastm = -1;
      for (let m = 0; m < faces[i].length - 1; ++m) {
        let n = faces[j].indexOf(faces[i][m]);
        if (n >= 0 && n < faces[j].length - 1) {
          if (lastm >= 0 && m !== lastm + 1) pairs.unshift([i, m], [j, n]);
          else pairs.push([i, m], [j, n]);
          lastm = m;
        }
      }
      if (pairs.length !== 4) continue;
      chamfer_faces.push([
        chamfer_faces[pairs[0][0]][pairs[0][1]],
        chamfer_faces[pairs[1][0]][pairs[1][1]],
        chamfer_faces[pairs[3][0]][pairs[3][1]],
        chamfer_faces[pairs[2][0]][pairs[2][1]],
        -1,
      ]);
    }
  }
  for (let i = 0; i < corner_faces.length; ++i) {
    let cf = corner_faces[i],
      face = [cf[0]],
      count = cf.length - 1;
    while (count) {
      for (let m = faces.length; m < chamfer_faces.length; ++m) {
        let index = chamfer_faces[m].indexOf(face[face.length - 1]);
        if (index >= 0 && index < 4) {
          if (--index === -1) index = 3;
          let next_vertex = chamfer_faces[m][index];
          if (cf.indexOf(next_vertex) >= 0) {
            face.push(next_vertex);
            break;
          }
        }
      }
      --count;
    }
    face.push(-1);
    chamfer_faces.push(face);
  }
  return { vectors: chamfer_vectors, faces: chamfer_faces };
};

const makeGeometry = (
  vertices: THREE.Vector3[],
  faces: number[][],
  radius: number,
  tab: number,
  af: number
): THREE.Geometry => {
  let geom = new THREE.Geometry();
  for (let i = 0; i < vertices.length; ++i) {
    let vertex = vertices[i].multiplyScalar(radius);
    //index = geom.vertices.push(vertex) - 1;
    geom.vertices.push(vertex);
  }
  for (let i = 0; i < faces.length; ++i) {
    let ii = faces[i],
      fl = ii.length - 1;
    let aa = (Math.PI * 2) / fl;
    for (let j = 0; j < fl - 2; ++j) {
      geom.faces.push(
        new THREE.Face3(
          ii[0],
          ii[j + 1],
          ii[j + 2],
          [
            geom.vertices[ii[0]],
            geom.vertices[ii[j + 1]],
            geom.vertices[ii[j + 2]],
          ],
          new THREE.Color("red"),
          ii[fl] + 1
        )
      );
      geom.faceVertexUvs[0].push([
        new THREE.Vector2(
          (Math.cos(af) + 1 + tab) / 2 / (1 + tab),
          (Math.sin(af) + 1 + tab) / 2 / (1 + tab)
        ),
        new THREE.Vector2(
          (Math.cos(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab),
          (Math.sin(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab)
        ),
        new THREE.Vector2(
          (Math.cos(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab),
          (Math.sin(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab)
        ),
      ]);
    }
  }
  geom.computeFaceNormals();
  geom.boundingSphere = new THREE.Sphere(new THREE.Vector3(), radius);
  return geom;
};

export { getChamferGeometry, makeGeometry };

import content from "../content/frontpage.json";
import { Card, Box, CardContent, Typography, useTheme } from "@mui/material";

export const About = () => {
  const theme = useTheme();

  return (
    <div
      style={{ paddingLeft: "10vw", paddingTop: "100px", paddingRight: "10vw" }}
    >
      <h1>{content.about.title}</h1>
      <Card sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {content.about.paragraphes.map((paragraph, index) => (
            <CardContent sx={{ flex: "1 0 auto" }} key={`about_${index}`}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                {paragraph}
              </Typography>
            </CardContent>
          ))}
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Contact me:{" "}
              <a
                href="mailto:info@dice2gether.com"
                style={{ color: theme.palette.primary.main }}
              >
                info@dice2gether.com
              </a>
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </div>
  );
};

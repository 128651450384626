import * as env from "./env";

export const devLog = (msg: String) => {
  if (env.isDev) {
    console.log(msg);
  }
};

export const getRandomInt = (min: number, max: number): number => {
  if (min > max) {
    throw "Invalid Argument: min is larger than max";
  }
  if (min === undefined) {
    return Math.floor(Math.random() * max);
  }
  return Math.floor(Math.random() * (max - min)) + min;
};

export const zeroPad = (num: number, pad: number) =>
  String(num).padStart(pad, "0");

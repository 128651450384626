import { createContext } from "react";
import io, { Socket } from "socket.io-client";
import * as env from "./env";
import { devLog } from "./utils";

export enum SessionResponse {
  JOINED,
  NOT_EXIST,
  FULL,
  TOO_MANY,
  CREATED,
  NOT_CONNECTED,
  UNKNOWN,
}

export enum UsernameResponse {
  DONE,
  ALREADY_TAKEN,
  EMPTY,
  NOT_CONNECTED,
  UNKNOWN,
}

class SocketConnection {
  url: string;
  path: string;
  socket: Socket | null;
  connectionId: string;
  sessionId: string;
  username: string;
  ready: boolean; // client is ready to accept data from the server

  constructor(url: string, path: string) {
    this.url = url;
    this.path = path;
    this.socket = null;
    this.connectionId = "";
    this.sessionId = "";
    this.username = "";
    this.ready = false;
  }

  connect(): Promise<void> {
    return new Promise((resolve) => {
      if (this.socket) {
        resolve();
      } else {
        this.socket = io(this.url, {
          path: this.path,
        });
        this.socket.on("connect", () => {
          // const connectionId = env.isDev
          //   ? "oERFSvgVzxjWWgZHAAAB"
          //   : this.socket!.id;
          const connectionId = this.socket!.id;
          devLog(`Connected with id ${connectionId}`);
          this.connectionId = connectionId;
          resolve();
        });
      }
    });
  }

  connected(): boolean {
    return this.connectionId !== "";
  }

  createSession(): Promise<SessionResponse> {
    return new Promise((resolve, reject) => {
      if (!this.connected()) {
        reject(SessionResponse.NOT_CONNECTED);
      } else {
        this.socket!.emit(
          "createSession",
          {},
          (res: { status: string; sessionId: string }) => {
            switch (res.status) {
              case "created":
                this.sessionId = res.sessionId;
                resolve(SessionResponse.CREATED);
                break;
              case "too_many":
                reject(SessionResponse.TOO_MANY);
                break;
              default:
                reject(SessionResponse.UNKNOWN);
                break;
            }
          }
        );
      }
    });
  }

  joinSession(sessionId: string): Promise<SessionResponse> {
    return new Promise((resolve, reject) => {
      if (!this.connected()) {
        reject({ status: SessionResponse.NOT_CONNECTED, sessionId: "" });
      } else {
        this.socket!.emit(
          "joinSession",
          { sessionId },
          (res: { status: string }) => {
            switch (res.status) {
              case "joined":
                this.sessionId = sessionId;
                resolve(SessionResponse.JOINED);
                break;
              case "not_exist":
                reject(SessionResponse.NOT_EXIST);
                break;
              case "full":
                reject(SessionResponse.FULL);
                break;
              default:
                reject(SessionResponse.UNKNOWN);
                break;
            }
          }
        );
      }
    });
  }

  setUsername(username: string): Promise<UsernameResponse> {
    return new Promise((resolve, reject) => {
      if (!this.connected) {
        reject(UsernameResponse.NOT_CONNECTED);
      } else {
        this.socket!.emit(
          "setUsername",
          { username },
          (res: { status: string }) => {
            switch (res.status) {
              case "done":
                this.username = username;
                resolve(UsernameResponse.DONE);
                break;
              case "already_taken":
                reject(UsernameResponse.ALREADY_TAKEN);
                break;
              case "empty":
                reject(UsernameResponse.EMPTY);
                break;
              default:
                reject(UsernameResponse.UNKNOWN);
                break;
            }
          }
        );
      }
    });
  }

  getDicePosition(diceId: number): Promise<[number, number, number] | null> {
    return new Promise((resolve, reject) => {
      if (!this.connected) {
        reject(null);
      } else {
        this.socket!.emit(
          "getDicePosition",
          { diceId },
          (res: { position: [number, number, number] }) => resolve(res.position)
        );
      }
    });
  }

  on(event: string, callback: (data: any) => void): void {
    if (!this.connected()) {
      return;
    }
    this.socket!.on(event, callback);
  }

  emit(event: string, data?: {}): void {
    if (!this.connected()) {
      return;
    }
    this.socket!.emit(event, data);
  }

  setReady() {
    this.ready = true;
    this.socket!.emit("clientReady");
  }

  isReady() {
    // client is ready to accept data from the server
    return this.ready;
  }

  close(): void {
    if (!this.connected()) {
      return;
    }
    this.socket!.close();
    this.socket = null;
    this.connectionId = "";
    this.sessionId = "";
    this.username = "";
    this.ready = false;
    devLog("closed connection");
  }

  getConnectionId(): string {
    return this.connectionId;
  }

  getSessionId(): string {
    return this.sessionId;
  }

  getUsername(): string {
    return this.username;
  }
}

export const connection = new SocketConnection(env.socketUrl, env.socketPath);
export const ConnectionContext = createContext<SocketConnection>(null!);

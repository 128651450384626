import { useRef, useState, useEffect, useContext } from "react";
import * as THREE from "three";
import Config from "../../configInterface";
import { getChamferGeometry, makeGeometry } from "./geometry";
import getMaterials from "./material";
import { GameState, useStore, useCameraStore } from "../store";
import { Color } from "../scene/dice";
import { ConnectionContext } from "../../connection";
import { useThree } from "@react-three/fiber";

export const DiceMesh = (props: {
  config: Config;
  playerId: string;
  diceId: number;
}) => {
  const [geometry, setGeometry] = useState<THREE.Geometry>(null!);
  const [materials, setMaterials] = useState<THREE.Material[]>(null!);
  const { setDiceHovered, playerDiceInfo } = useStore();
  const { setCameraPosition, setCameraTarget } = useCameraStore();
  const connection = useContext(ConnectionContext);
  const { invalidate } = useThree();
  const [position, setPosition] = useState<THREE.Vector3>(
    new THREE.Vector3(0, -100, 0)
  );
  const meshRef = useRef<THREE.Mesh>(null!);

  const handleHover = (hover: boolean) => {
    if (props.playerId == connection.getConnectionId()) {
      // only hover own dice
      setDiceHovered(props.playerId, props.diceId, hover);
    }
  };

  const handleClick = async () => {
    if (props.playerId == connection.getConnectionId()) {
      const position = await connection.getDicePosition(props.diceId);
      if (!position) return;
      // look onto the dice
      setCameraTarget(new THREE.Vector3(...position));
      // set the camera above the dice
      setCameraPosition(new THREE.Vector3(position[0], 20, position[2]));
    }
  };

  useEffect(() => {
    const radius = props.config.size * props.config.scaleFactor;

    const vectors = new Array(props.config.vertices.length);
    for (let i = 0; i < props.config.vertices.length; ++i) {
      vectors[i] = new THREE.Vector3()
        .fromArray(props.config.vertices[i])
        .normalize();
    }

    const chamferGeometry = getChamferGeometry(
      vectors,
      props.config.faces,
      props.config.chamfer
    );
    const geometry = makeGeometry(
      chamferGeometry.vectors,
      chamferGeometry.faces,
      radius,
      props.config.tab,
      props.config.afFactor * Math.PI
    );
    setGeometry(() => geometry);

    const index = playerDiceInfo[props.playerId].findIndex(
      (diceInfo) => diceInfo.id === props.diceId
    );
    const hovered = playerDiceInfo[props.playerId][index]["hovered"];

    let faceColor: Color;
    if (playerDiceInfo[props.playerId][index]["color"]) {
      faceColor = playerDiceInfo[props.playerId][index]["color"];
    } else {
      // fallback when color was not received yet
      faceColor = { red: 255, green: 0, blue: 0 };
    }
    if (hovered) {
      faceColor = invertColor(faceColor);
    }

    const materials = getMaterials(
      props.config.diceType,
      props.config.labelColor,
      faceColor,
      props.config.size,
      props.config.textMargin
    );
    setMaterials(() => materials);
  }, [playerDiceInfo]);

  useEffect(() => {
    async function initPosition() {
      const newPosition = await connection.getDicePosition(props.diceId);
      if (!newPosition) return;
      setPosition(() => new THREE.Vector3(...newPosition));
    }
    initPosition();

    // TODO: move this to the scene component to avoid
    // creating a listener for each dice
    connection.on("update", (state: GameState) => {
      if (
        state.hasOwnProperty(props.playerId) &&
        state[props.playerId].hasOwnProperty(props.diceId) &&
        meshRef &&
        meshRef.current
      ) {
        // set the new position and quaternion
        meshRef.current.position.copy(
          state[props.playerId][props.diceId].position
        );
        meshRef.current.quaternion.copy(
          state[props.playerId][props.diceId].quaternion
        );
        // request a new frame
        invalidate();
      }
    });
  }, []);

  if (!geometry || !materials) {
    return <mesh />;
  }

  return (
    <mesh
      ref={meshRef}
      position={position}
      receiveShadow={true}
      castShadow={true}
      geometry={geometry}
      material={materials}
      onPointerOver={() => handleHover(true)}
      onPointerOut={() => handleHover(false)}
      onClick={handleClick}
    />
  );
};

const invertColor = (color: Color): Color => {
  return {
    red: 255 - color.red,
    green: 255 - color.green,
    blue: 255 - color.blue,
  };
};

import { SessionForm } from "../components/frontpage/sessionForm";
import { Background } from "../components/frontpage/background";
//import { FeatureCard } from "../components/frontpage/featureCard";
//import content from "../content/frontpage.json";
//import { apiUrl } from "../env";

export const Frontpage = () => {
  return (
    <div>
      <Background />
      <SessionForm />
      <div
        style={{
          position: "absolute",
          top: "100vh",
          paddingBottom: "50px",
        }}
      ></div>
    </div>
  );
};

//{content.features.map((feature, index) => (
//  <FeatureCard
//    imagePath={`${apiUrl}assets/images/feature/feature${index}.png`}
//    title={feature.title}
//    description={feature.description}
//    imagePosition={index % 2 == 0 ? "left" : "right"}
//    key={`featurecard_${index}`}
//  />
//))}

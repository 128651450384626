import {
  capitalize,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";
import {
  Add,
  Casino,
  Reply,
  ReplyAll,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { diceTypes, DiceType, DiceInfo } from "../dice";
import { useStore, useCameraStore } from "../../store";
import { useContext, useState } from "react";
import { ConnectionContext } from "../../../connection";
import * as THREE from "three";
import { responsiveUIList } from "../../../responsive";

export const DiceList = () => {
  const { playerDiceInfo } = useStore();
  const connection = useContext(ConnectionContext);

  if (!playerDiceInfo.hasOwnProperty(connection.getConnectionId())) {
    return <List />;
  }

  const handleThrowAll = async () => {
    connection.emit("throwAllDice");
  };

  return (
    <List
      sx={{
        position: "absolute",
        top: responsiveUIList.diceListTop,
        bottom: responsiveUIList.diceListBottom,
        left: responsiveUIList.diceListLeft,
        right: responsiveUIList.diceListRight,
        backgroundColor: "#262626",
        opacity: 0.75,
        borderRadius: 2,
      }}
      dense
      disablePadding
    >
      <ListItem disablePadding dense button sx={{ position: "relative" }}>
        <ListItemButton onClick={handleThrowAll}>
          <ListItemIcon sx={{ minWidth: responsiveUIList.iconMinWidth }}>
            <ReplyAll sx={{ fontSize: responsiveUIList.iconSize }} />
          </ListItemIcon>
          <ListItemText
            primary="Throw all"
            primaryTypographyProps={{ fontSize: responsiveUIList.fontSize }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
      {playerDiceInfo[connection.getConnectionId()]
        .slice(0)
        .reverse()
        .map((diceInfo) => (
          <DiceListItem diceInfo={diceInfo} key={diceInfo.id} />
        ))}
      <Divider />
      <AddDiceListItem connectionId={connection.getConnectionId()} />
    </List>
  );
};

const DiceListItem = (props: { diceInfo: DiceInfo }) => {
  const { setDiceHovered } = useStore();
  const { setCameraPosition, setCameraTarget } = useCameraStore();
  const connection = useContext(ConnectionContext);

  const handleThrow = async () => {
    connection.emit("throwSingleDice", { diceId: props.diceInfo.id });
  };

  const handleDeletion = async () => {
    connection.emit("deleteDice", { diceId: props.diceInfo.id });
  };

  const handleFocused = async () => {
    if (connection.connected()) {
      const position = await connection.getDicePosition(props.diceInfo.id);
      if (!position) return;
      // look onto the dice
      setCameraTarget(new THREE.Vector3(...position));
      // set the camera above the dice
      setCameraPosition(new THREE.Vector3(position[0], 20, position[2]));
    }
  };

  const handleMouseOver = (over: boolean) => {
    if (connection.connected()) {
      setDiceHovered(connection.getConnectionId(), props.diceInfo.id, over);
    }
  };

  return (
    <ListItem
      sx={{ position: "relative" }}
      secondaryAction={
        <div>
          <IconButton onClick={handleFocused}>
            <VisibilityIcon
              sx={{
                fontSize: responsiveUIList.iconSize,
              }}
            />
          </IconButton>
          <IconButton onClick={handleThrow}>
            <Reply sx={{ fontSize: responsiveUIList.iconSize }} />
          </IconButton>
          <IconButton onClick={handleDeletion}>
            <DeleteIcon sx={{ fontSize: responsiveUIList.iconSize }} />
          </IconButton>
        </div>
      }
      disablePadding
      dense
    >
      <ListItemButton
        selected={props.diceInfo.hovered}
        onMouseEnter={() => handleMouseOver(true)}
        onMouseLeave={() => handleMouseOver(false)}
      >
        <ListItemIcon sx={{ minWidth: responsiveUIList.iconMinWidth }}>
          <Casino sx={{ fontSize: responsiveUIList.iconSize }} />
        </ListItemIcon>
        <ListItemText
          primary={capitalize(props.diceInfo.type)}
          primaryTypographyProps={{ fontSize: responsiveUIList.fontSize }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const AddDiceListItem = (props: { connectionId: String }) => {
  const [open, setOpen] = useState<boolean>(false);
  const connection = useContext(ConnectionContext);

  const toggleMenu = () => {
    setOpen(() => !open);
  };

  const closeMenu = () => {
    setOpen(() => false);
  };

  const handleAddDice = async (diceType: DiceType) => {
    connection.emit("addDice", { diceType });
    closeMenu();
  };

  return (
    <div>
      <ListItem disablePadding dense>
        <ListItemButton onClick={toggleMenu}>
          <ListItemIcon sx={{ minWidth: responsiveUIList.iconMinWidth }}>
            <Add sx={{ fontSize: responsiveUIList.iconSize }} />
          </ListItemIcon>
          <ListItemText
            primary="Add dice"
            primaryTypographyProps={{ fontSize: responsiveUIList.fontSize }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {diceTypes.map((diceType) => (
            <ListItem disablePadding key={diceType}>
              <ListItemButton
                sx={{ pl: 0 }}
                onClick={() => handleAddDice(diceType)}
              >
                <ListItemText
                  primary={capitalize(diceType)}
                  primaryTypographyProps={{
                    fontSize: responsiveUIList.fontSize,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

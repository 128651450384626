import ReactDOM from "react-dom";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import "./css/index.css";
import { MenuBar } from "./components/menuBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Session } from "./routes/session";
import { Frontpage } from "./routes/frontpage";
import { connection, ConnectionContext } from "./connection";
import { ToastContainer } from "react-toastify";
import { About } from "./routes/about";
import "react-toastify/dist/ReactToastify.min.css";

const App = () => {
  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#914e0f",
      },
    },
    components: {
      MuiListItemSecondaryAction: {
        // override global style for secondary action buttons
        styleOverrides: {
          root: {
            position: "relative",
            top: "20px",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ConnectionContext.Provider value={connection}>
        <CssBaseline />
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <BrowserRouter>
          <MenuBar />
          <Routes>
            <Route path="/" element={<Frontpage />} />
            <Route path="/about" element={<About />} />
            <Route path="/session/:sessionId" element={<Session />} />
          </Routes>
        </BrowserRouter>
      </ConnectionContext.Provider>
    </ThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

import { assetUrl } from "../../env";
import { zeroPad } from "../../utils";
import "./backgroundImage.css";
import { useState, useEffect } from "react";

interface BackgroundImageProps {
  imageIndex: number;
  zIndex: number;
  dimAnimation?: {
    durationSeconds: number;
    delay: number;
  };
}

export const BackgroundImage = ({
  imageIndex,
  zIndex,
  dimAnimation,
}: BackgroundImageProps) => {
  const [style, setStyle] = useState<Object>(null!);

  useEffect(() => {
    const filename = `${zeroPad(imageIndex, 5)}.png`;
    const imageUrl = new URL(`images/background/${filename}`, assetUrl).href;
    const newStyle: any = {
      backgroundImage: `url(${imageUrl})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100vw",
      height: "100vh",
      filter: "brightness(0.5)",
      position: "absolute",
      zIndex: zIndex,
    };
    if (dimAnimation) {
      newStyle["animationName"] = "fade";
      newStyle["animationDuration"] = `${dimAnimation.durationSeconds}s`;
      newStyle["animationDelay"] = `${dimAnimation.delay}s`;
      newStyle["animationIterationCount"] = "infinite";
    }
    setStyle(newStyle);
  }, [imageIndex, dimAnimation]);

  return <div style={style} />;
};

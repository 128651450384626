// taken from https://github.com/byWulf/threejs-dice/blob/master/lib/dice.js
import * as THREE from "three";
import { Color } from "../../components/scene/dice";
import { DiceType } from "./dice";

const standardFaceTexts = [
  " ",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6.",
  "7",
  "8",
  "9.",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];

const d4FaceTexts = ["", "000", "243", "134", "214", "123"];

const materialOptions = {
  // specular: 0x172022,
  // color: 0xf0f0f0,
  // shininess: 40,
  flatShading: true,
  metalness: 0,
};

const calculateTextureSize = (approx: number) => {
  return Math.max(128, Math.pow(2, Math.floor(Math.log(approx) / Math.log(2))));
};

const createTextTexture = (
  text: string,
  color: Color,
  backColor: Color,
  size: number,
  textMargin: number
): THREE.Texture | null => {
  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  if (context === null) {
    console.log("Failed to get 2D context");
    return null;
  }
  let ts = calculateTextureSize(size / 2 + size * textMargin) * 2;
  canvas.width = canvas.height = ts;
  context.font = `bold ${ts / (1 + 2 * textMargin)}pt Arial`;
  context.fillStyle = `rgb(${backColor.red}, ${backColor.green}, ${backColor.blue})`;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillStyle = `rgb(${color.red}, ${color.green}, ${color.blue})`;
  context.fillText(text, canvas.width / 2, canvas.height / 2);
  let texture = new THREE.Texture(canvas);
  texture.needsUpdate = true;
  return texture;
};

const createD4TextTexture = (
  text: string,
  color: Color,
  backColor: Color,
  size: number,
  textMargin: number
): THREE.Texture | null => {
  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  if (context === null) {
    console.log("Failed to get 2D context");
    return null;
  }
  let ts = calculateTextureSize(size / 2 + size * 2) * 2;
  canvas.width = canvas.height = ts;
  context.font = ts / 4 + "pt Arial";
  context.fillStyle = `rgb(${backColor.red}, ${backColor.green}, ${backColor.blue})`;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillStyle = `rgb(${color.red}, ${color.green}, ${color.blue})`;
  for (let digit of text) {
    context.fillText(digit, canvas.width / 2, canvas.height / 2 - ts * 0.27);
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate((Math.PI * 2) / 3);
    context.translate(-canvas.width / 2, -canvas.height / 2);
  }
  let texture = new THREE.Texture(canvas);
  texture.needsUpdate = true;
  return texture;
};

const getMaterials = (
  diceType: DiceType,
  labelColor: Color,
  diceColor: Color,
  size: number,
  textMargin: number
): THREE.Material[] => {
  let materials = [];
  let faceTexts = diceType == "dice4" ? d4FaceTexts : standardFaceTexts;
  for (let i = 0; i < faceTexts.length; ++i) {
    const textureFn =
      diceType == "dice4" ? createD4TextTexture : createTextTexture;
    let texture = textureFn(
      faceTexts[i],
      labelColor,
      diceColor,
      size,
      textMargin
    );
    if (texture === null) {
      materials.push(new THREE.MeshStandardMaterial());
    } else {
      const params = Object.assign({}, materialOptions, { map: texture });
      materials.push(new THREE.MeshStandardMaterial(params));
    }
  }
  return materials;
};

export default getMaterials;

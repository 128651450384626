import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Config from "../../configInterface";
import { useStore } from "../store";
import * as env from "../../env";
import { DiceMesh } from "./diceMesh";
import { ConnectionContext } from "../../connection";

// https://stackoverflow.com/a/59420158
export const diceTypes = [
  "dice4",
  "dice6",
  "dice8",
  "dice10",
  "dice12",
  "dice20",
] as const;
export type DiceType = typeof diceTypes[number];

export interface Color {
  red: number;
  green: number;
  blue: number;
}

export interface DiceInfo {
  id: number;
  type: DiceType;
  hovered: boolean;
  color: Color;
}

export const DiceCollection = () => {
  const { playerIds, playerDiceInfo } = useStore();
  const connection = useContext(ConnectionContext);

  if (!connection.isReady()) {
    // only start rendering the dice when the client is ready to handle server events
    return null;
  }

  return (
    <group>
      {playerIds.map((playerId) =>
        playerDiceInfo[playerId].map((diceInfo) => (
          <Dice
            playerId={playerId}
            diceInfo={diceInfo}
            key={playerId + `_${diceInfo.id}`}
          />
        ))
      )}
    </group>
  );
};

const Dice = (props: { playerId: string; diceInfo: DiceInfo }) => {
  const [config, setConfig] = useState<Config | null>(null);

  // using the empty array makes the function being called just once,
  // see: https://stackoverflow.com/a/53121021
  // async in useEffect: https://github.com/facebook/react/issues/14326
  useEffect(() => {
    async function fetchConfig() {
      try {
        const res = await axios.get(
          new URL(`config/dice/${props.diceInfo.type}.json`, env.assetUrl).href
        );
        const data: Config = await res.data;
        setConfig(() => data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchConfig();
  }, []);

  if (config) {
    return (
      <DiceMesh
        config={config}
        playerId={props.playerId}
        diceId={props.diceInfo.id}
      />
    );
  } else {
    return <mesh />;
  }
};

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router";

export const MenuBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const onNameClick = () => {
    navigate("/");
  };

  const onAboutClick = () => {
    navigate("/about");
  };

  return (
    <AppBar position="fixed" color="default">
      <Toolbar>
        {/* <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton> */}
        <Typography
          variant="h5"
          component="div"
          sx={{ flexGrow: 1, cursor: "pointer" }}
          onClick={onNameClick}
        >
          Dice2Gether
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{ cursor: "pointer", marginLeft: "auto" }}
          onClick={onAboutClick}
        >
          About
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

import {
  Grid,
  Stack,
  TextField,
  Button,
  Typography,
  Paper,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { ConnectionContext } from "../../connection";
import { devLog } from "../../utils";
import { SessionResponse } from "../../connection";
import content from "../../content/frontpage.json";
import { responsiveCentered } from "../../responsive";

export const SessionForm = () => {
  const [sessionId, setSessionId] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const navigate = useNavigate();
  const connection = useContext(ConnectionContext);

  const handleJoinSession = async (sessId: string) => {
    if (!connection.connected()) {
      await connection.connect();
    }
    try {
      await connection.joinSession(sessId);
      devLog("joined session");
      navigate(`/session/${sessId}`);
    } catch (error) {
      switch (error) {
        case SessionResponse.NOT_EXIST:
          setErrorMsg(() => "Session does not exist");
          break;
        case SessionResponse.FULL:
          setErrorMsg(() => "Session is full");
          break;
        default:
          setErrorMsg(() => "Unknown error");
          break;
      }
    }
  };

  const handleNewSession = async () => {
    await connection.connect();
    try {
      const res = await connection.createSession();
      const newSessionId = connection.getSessionId();
      devLog(`session ${newSessionId} created`);
      handleJoinSession(newSessionId);
    } catch (error) {
      switch (error) {
        case SessionResponse.TOO_MANY:
          setErrorMsg(() => "Too many sessions, please try again later");
          break;
        default:
          setErrorMsg(() => "Unknown error");
          break;
      }
    }
  };

  return (
    <div className="content">
      <Paper
        sx={{
          width: responsiveCentered.width,
          minHeight: "28vh",
          filter: "opacity(0.85)",
          zIndex: 3,
          left: responsiveCentered.left,
          top: "35vh",
          position: "absolute",
        }}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          margin="0px"
          width="100%"
          height="100%"
        >
          <Grid item xs={3} padding="16px">
            <Typography sx={{ textTransform: "none", fontSize: 20 }}>
              {content.welcomeText}
            </Typography>
          </Grid>
          <Grid item xs={3} padding="16px">
            <Stack direction="row" spacing={2} sx={{ height: 55 }}>
              <TextField
                id="outlined-basic"
                label="Session ID"
                variant="outlined"
                onChange={(event) => setSessionId(() => event.target.value)}
              />
              <Button
                variant="contained"
                onClick={() => handleJoinSession(sessionId)}
              >
                <Typography sx={{ textTransform: "none", fontWeight: "bold" }}>
                  Join session
                </Typography>
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={3} padding="16px">
            <Button
              variant="contained"
              onClick={handleNewSession}
              sx={{ height: 55 }}
            >
              <Typography sx={{ textTransform: "none", fontWeight: "bold" }}>
                Create new session
              </Typography>
            </Button>
          </Grid>
          {errorMsg ? (
            <Grid item xs={3} padding="16px">
              <Typography sx={{ textTransform: "none", color: "error.main" }}>
                {errorMsg}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </div>
  );
};

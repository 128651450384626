import * as THREE from "three";
import { useRef, Suspense, useContext, ReactNode, useEffect } from "react";
import {
  Canvas,
  useFrame,
  useThree,
  extend,
  useLoader,
} from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DiceCollection } from "./dice";
import { ConnectionContext } from "../../connection";
import * as env from "../../env";
import { useCameraStore } from "../store";

const ContextCanvas = (props: { children: ReactNode }) => {
  // React context does not work through the Canvas component:
  // https://github.com/pmndrs/react-three-fiber/issues/262#issuecomment-568274573
  const connection = useContext(ConnectionContext);

  return (
    <Canvas
      style={{ height: "100vh" }}
      camera={{
        fov: 45,
        aspect: window.innerWidth / window.innerHeight,
        near: 0.01,
        far: 20000,
        position: [0, 60, 60],
      }}
      frameloop="demand"
    >
      <ConnectionContext.Provider value={connection}>
        {props.children}
      </ConnectionContext.Provider>
    </Canvas>
  );
};

export const Scene = () => {
  return (
    <ContextCanvas>
      <CameraControls />
      <Light />
      {/* <fogExp2 attach="fog" args={["#212121", 0.005]} /> */}
      {/* <fog attach="fog" args={["#212121", 50, 100]} /> */}
      <Suspense fallback={null}>
        <Table radius={300} />
        <Floor length={50} />
        <Walls length={50} height={8} width={2} />
        <Background length={10000} />
      </Suspense>
      <DiceCollection />
    </ContextCanvas>
  );
};

const Light = () => {
  const threeState = useThree();
  threeState.gl.shadowMap.enabled = true;
  threeState.gl.shadowMap.type = THREE.PCFSoftShadowMap;
  threeState.gl.setClearColor("#303030");
  return (
    <group>
      <ambientLight color={"#ffffff"} intensity={0.1} />
      {/* <directionalLight
        args={["#ffffff", 0.1]}
        position-x={-100}
        position-y={100}
        position-z={100}
        castShadow={true}
      /> */}
      <spotLight
        args={[0xefdfd5, 0.4]}
        position-y={170}
        position-z={-50}
        position-x={50}
        target-position={[0, 0, 0]}
        castShadow={true}
        shadow-camera-near={10}
        shadow-camera-far={1000}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
    </group>
  );
};

const Walls = (props: { length: number; height: number; width: number }) => {
  // top, bottom, left, right
  return (
    <group>
      <Wall
        size={[props.length, props.height, props.width]}
        position={[0, props.height / 2, -25 - props.width / 2]}
      />
      <Wall
        size={[props.length, props.height, props.width]}
        position={[0, props.height / 2, 25 + props.width / 2]}
      />
      <Wall
        size={[props.width, props.height, props.length + 2 * props.width]}
        position={[-25 - props.width / 2, props.height / 2, 0]}
      />
      <Wall
        size={[props.width, props.height, props.length + 2 * props.width]}
        position={[25 + props.width / 2, props.height / 2, 0]}
      />
    </group>
  );
};

const Wall = (props: { size: number[]; position: number[] }) => {
  const wallTexture = useLoader(
    THREE.TextureLoader,
    new URL("textures/wall.jpg", env.assetUrl).href
  );
  const threeState = useThree();
  wallTexture.anisotropy = threeState.gl.capabilities.getMaxAnisotropy();

  return (
    <mesh
      receiveShadow={true}
      castShadow={true}
      position={new THREE.Vector3(...props.position)}
    >
      <boxGeometry args={[props.size[0], props.size[1], props.size[2]]} />
      <meshStandardMaterial map={wallTexture} metalness={0} />
    </mesh>
  );
};

const Floor = (props: { length: number }) => {
  const floorTexture = useLoader(
    THREE.TextureLoader,
    new URL("textures/floor.jpg", env.assetUrl).href
  );
  const threeState = useThree();
  floorTexture.anisotropy = threeState.gl.capabilities.getMaxAnisotropy();

  return (
    <mesh receiveShadow={true} rotation-x={Math.PI / 2}>
      <planeGeometry args={[props.length, props.length, 10, 10]} />
      <meshStandardMaterial
        side={THREE.BackSide}
        map={floorTexture}
        metalness={0}
      />
    </mesh>
  );
};

const Table = (props: { radius: number }) => {
  const floorTexture = useLoader(
    THREE.TextureLoader,
    new URL("textures/table.jpg", env.assetUrl).href
  );
  const threeState = useThree();
  floorTexture.anisotropy = threeState.gl.capabilities.getMaxAnisotropy();
  // floorTexture.wrapS = THREE.RepeatWrapping;
  // floorTexture.wrapT = THREE.RepeatWrapping;
  // floorTexture.repeat.set(4, 4);

  return (
    <mesh receiveShadow={true} rotation-x={Math.PI / 2} position-y={-0.5}>
      <circleGeometry args={[props.radius, 64]} />
      <meshStandardMaterial
        side={THREE.BackSide}
        map={floorTexture}
        metalness={0}
      />
    </mesh>
  );
};

const Background = (props: { length: number }) => {
  const bgTexture = useLoader(
    THREE.TextureLoader,
    new URL("textures/background.jpg", env.assetUrl).href
  );
  const threeState = useThree();
  bgTexture.anisotropy = threeState.gl.capabilities.getMaxAnisotropy();

  return (
    <mesh receiveShadow={true}>
      <boxGeometry args={[props.length, props.length, props.length]} />
      <meshStandardMaterial
        side={THREE.BackSide}
        map={bgTexture}
        metalness={0}
      />
    </mesh>
  );
};

extend({ OrbitControls });

const CameraControls = () => {
  // from: https://codeworkshop.dev/blog/2020-04-03-adding-orbit-controls-to-react-three-fiber/
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls component.
  // https://threejs.org/docs/#examples/en/controls/OrbitControl
  const {
    camera,
    gl: { domElement },
    invalidate,
  } = useThree();

  //useThree(({ camera }) => {
  //  camera.position.set(0, 30, 30);
  //});

  // https://github.com/ocio/three-camera-utils/blob/0d8b0589e19a3e30a8ba4cf64773c7cb1a5c36b3/src/index.js#L100
  // https://discourse.threejs.org/t/how-to-limit-pan-in-orbitcontrols-for-orthographiccamera/9061
  const createOnChangeHandler = () => {
    // these values should not be set each time the handler is called
    const tmpVec = new THREE.Vector3();
    const minPan = new THREE.Vector3(-50, 0, -50);
    const maxPan = new THREE.Vector3(50, 100, 50);
    return () => {
      // limit panning
      tmpVec.copy(controls.current.target);
      controls.current.target.clamp(minPan, maxPan);
      tmpVec.sub(controls.current.target);
      camera.position.sub(tmpVec);
      // request the next frame
      invalidate();
    };
  };

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef<OrbitControls>(null!);
  if (controls && controls.current) {
    controls.current.minPolarAngle = 0;
    // do not rotate below the ground/table
    controls.current.maxPolarAngle = Math.PI / 2 - 0.01;
    // max zoom out distance
    controls.current.maxDistance = 200;
    // disable movement/panning with right mouse button
    // controls.current.enablePan = false;
    controls.current.addEventListener("change", createOnChangeHandler());
  }

  useEffect(() => {
    useCameraStore.subscribe((state) => {
      controls.current.target.copy(state.cameraTarget);
      camera.position.copy(state.cameraPosition);
      invalidate();
    });
  }, []);

  useFrame(() => controls.current.update());
  // @ts-ignore
  return <orbitControls ref={controls} args={[camera, domElement]} />;
};

import create from "zustand";
import { DiceInfo } from "./scene/dice";
import * as THREE from "three";

export interface GameState {
  [playerId: string]: {
    [diceId: number]: {
      position: THREE.Vector3;
      quaternion: THREE.Quaternion;
    };
  };
}

export interface PlayerDiceInfo {
  [playerId: string]: DiceInfo[];
}

export interface Store {
  playerIds: string[];
  playerNames: string[];
  playerDiceInfo: PlayerDiceInfo;

  addPlayer: (playerId: string, playerName: string) => void;
  addDiceToPlayer: (playerId: string, diceInfo: DiceInfo) => void;
  removePlayer: (playerId: string) => void;
  deleteDiceOfPlayer: (playerId: string, diceId: number) => void;
  setDiceHovered: (playerId: string, diceId: number, hovered: boolean) => void;
}

// This is the store used with the React render loop for adding/removing new dice and UI changes.
// It is updated when e.g. a new dice is added or a dice is hovered.
export const useStore = create<Store>((set) => ({
  playerIds: [],
  playerNames: [],
  playerDiceInfo: {},

  addPlayer: (playerId: string, playerName: string) =>
    set((prevState) => ({
      playerIds: [playerId, ...prevState.playerIds],
      playerNames: [playerName, ...prevState.playerNames],
      playerDiceInfo: { [playerId]: [], ...prevState.playerDiceInfo },
    })),

  addDiceToPlayer: (playerId: string, diceInfo: DiceInfo) =>
    set((prevState) => ({
      playerDiceInfo: {
        ...prevState.playerDiceInfo,
        [playerId]: [diceInfo, ...prevState.playerDiceInfo[playerId]],
      },
    })),

  removePlayer: (playerId: string) =>
    set((prevState) => {
      let newPlayerDiceInfo: PlayerDiceInfo = { ...prevState.playerDiceInfo };
      delete newPlayerDiceInfo[playerId];
      const index = prevState.playerIds.indexOf(playerId);
      const playerName = prevState.playerNames[index];
      return {
        playerIds: prevState.playerIds.filter((id) => id !== playerId),
        playerNames: prevState.playerNames.filter(
          (name) => name !== playerName
        ),
        playerDiceInfo: newPlayerDiceInfo,
      };
    }),

  deleteDiceOfPlayer: (playerId: string, diceId: number) =>
    set((prevState) => ({
      playerDiceInfo: {
        ...prevState.playerDiceInfo,
        [playerId]: prevState.playerDiceInfo[playerId].filter(
          (diceInfo) => diceInfo.id !== diceId
        ),
      },
    })),

  setDiceHovered: (playerId: string, diceId: number, hovered: boolean) =>
    set((prevState) => {
      let newPlayerDiceInfo: PlayerDiceInfo = { ...prevState.playerDiceInfo };
      const index = newPlayerDiceInfo[playerId].findIndex(
        (diceInfo) => diceInfo.id === diceId
      );
      newPlayerDiceInfo[playerId][index]["hovered"] = hovered;
      return {
        playerDiceInfo: newPlayerDiceInfo,
      };
    }),
}));

export interface CameraStore {
  cameraPosition: THREE.Vector3;
  cameraTarget: THREE.Vector3;

  setCameraPosition: (newPosition: THREE.Vector3) => void;
  setCameraTarget: (newTarget: THREE.Vector3) => void;
  resetCamera: () => void;
}

export const useCameraStore = create<CameraStore>((set) => ({
  cameraPosition: new THREE.Vector3(0, 60, 60),
  cameraTarget: new THREE.Vector3(0, 0, 0),

  setCameraPosition: (newPosition: THREE.Vector3) =>
    set(() => ({
      cameraPosition: newPosition,
    })),

  setCameraTarget: (newTarget: THREE.Vector3) =>
    set(() => ({
      cameraTarget: newTarget,
    })),

  resetCamera: () =>
    set(() => ({
      cameraPosition: new THREE.Vector3(0, 60, 60),
      cameraTarget: new THREE.Vector3(0, 0, 0),
    })),
}));

export interface ImageBackgroudStore {
  imageIndex: number;

  setBackgroundImageIndex: (newIndex: number) => void;
}

export const useImageBackgroundStore = create<ImageBackgroudStore>((set) => ({
  imageIndex: 0,

  setBackgroundImageIndex: (newIndex: number) =>
    set(() => ({
      imageIndex: newIndex,
    })),
}));
